<template>
  <div class="productos mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Productos
          </v-card-title>
          <v-card-subtitle>
            Desde este apartado podrás modificar el valor de tus productos.
            <br />
            <strong>
              El IVA solo afecta bajo un regimen diferente al simplificado
            </strong>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="lista"
                  :footer-props="{
                    itemsPerPageText: 'Productos',
                    itemsPerPageOptions: [10, 25, 50, 100],
                  }"
                  :search="search"
                  :options="{
                    itemsPerPage: 50,
                  }"
                >
                  <template v-slot:top>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field
                          outlined
                          rounded
                          dense
                          append-icon="mdi-magnify"
                          label="Buscar"
                          v-model="search"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-btn dark color="black" @click="download()">
                          <v-icon left>mdi-download</v-icon>
                          Descargar precios
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-row no-gutters>
                          <v-col cols="12" md="3" sm="6">
                            <v-checkbox
                              v-model="venta"
                              label="Productos de venta"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="3" sm="6">
                            <v-checkbox
                              v-model="consumo"
                              label="Productos de consumo"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.compra="{ item }">
                    {{ item.compra | currency }}
                  </template>
                  <template v-slot:item.iva_compra="{ item }">
                    {{ item.iva_compra }}%
                  </template>
                  <template v-slot:item.venta="{ item }">
                    {{ item.venta | currency }}
                  </template>
                  <template v-slot:item.iva_venta="{ item }">
                    {{ item.iva_compra }}%
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-btn
                      icon
                      small
                      color="primary"
                      @click="setEditValue(item)"
                    >
                      <v-icon>mdi-pen</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-dialog
                  v-model="dialog"
                  persistent
                  scrollable
                  max-width="600px"
                >
                  <v-card v-if="producto">
                    <v-card-title>
                      {{ producto.producto }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ producto.codigo }}
                    </v-card-subtitle>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            dense
                            type="text"
                            :label="
                              `Precio de compra ${$options.filters.currency(
                                producto.compra
                              )}`
                            "
                            v-model.number="producto.compra"
                            class="mt-2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            dense
                            type="text"
                            :label="`IVA compra: ${producto.iva_compra}%`"
                            v-model.number="producto.iva_compra"
                            class="mt-2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            dense
                            type="text"
                            :label="
                              `Precio de venta ${$options.filters.currency(
                                producto.venta
                              )}`
                            "
                            v-model.number="producto.venta"
                            class="mt-2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field
                            dense
                            type="text"
                            :label="`IVA venta: ${producto.iva_venta}%`"
                            v-model.number="producto.iva_venta"
                            class="mt-2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <v-text-field
                            dense
                            readonly
                            type="text"
                            label="% Utilidad"
                            :value="utility()"
                            class="mt-2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cancel()">
                        Cancelar
                      </v-btn>
                      <v-btn
                        :dark="!loading"
                        :color="!loading ? 'black' : 'gray'"
                        :loading="loading"
                        :disabled="loading"
                        @click="updateItem()"
                      >
                        Actualizar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
export default {
  name: "productos",
  data: () => ({
    search: "",
    expanded: [],
    setValue: 0,
    headers: [
      {
        text: "Código",
        value: "codigo",
        width: 100,
      },
      {
        text: "Producto",
        value: "producto",
        width: 150,
      },
      {
        text: "Stock",
        value: "stock",
        width: 50,
      },
      {
        text: "Compra gr / und",
        value: "compra",
        width: 100,
        sortable: false,
      },
      {
        text: "IVA compra",
        value: "iva_compra",
        width: 100,
      },
      {
        text: "Venta",
        value: "venta",
        width: 100,
      },
      {
        text: "IVA venta",
        value: "iva_venta",
        width: 100,
      },
      {
        text: "",
        value: "edit",
        width: 50,
        sortable: false,
      },
    ],
    venta: true,
    consumo: true,
    productos: [],
    producto: null,
    loading_t: false,
    loading: false,
    dialog: false,
  }),
  methods: {
    loadProductos() {
      this.loading_t = true;
      const body = {
        route: "/productos_activos",
      };
      this.$store
        .dispatch("axios_get", body)
        .then((response) => {
          if (response.data.success) {
            this.productos = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    setEditValue(item) {
      this.producto = item;
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
      this.producto = null;
    },
    updateItem() {
      this.loading = true;
      this.producto.compra = isNaN(this.producto.compra)
        ? 0
        : this.producto.compra === ""
        ? 0
        : this.producto.compra;
      this.producto.iva_compra = isNaN(this.producto.iva_compra)
        ? 0
        : this.producto.iva_compra === ""
        ? 0
        : this.producto.iva_compra;
      this.producto.venta = isNaN(this.producto.venta)
        ? 0
        : this.producto.venta === ""
        ? 0
        : this.producto.venta;
      this.producto.iva_venta = isNaN(this.producto.iva_venta)
        ? 0
        : this.producto.iva_venta === ""
        ? 0
        : this.producto.iva_venta;
      const body = {
        route: "/update_productos_valor",
        data: {
          data: {
            compra: this.producto.compra,
            iva_compra: this.producto.iva_compra,
            venta: this.producto.venta,
            iva_venta: this.producto.iva_venta,
          },
          id: this.producto.id_activo,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.dialog = false;
            this.producto = null;
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    download() {
      const name = "Listado-productos-precios";
      const data = this.lista.map((producto) => {
        return {
          "Codigo sistema": producto.sistema,
          Codigo: producto.codigo,
          Producto: producto.producto,
          Stock: producto.inventario == 1 ? "Venta" : "Consumo",
          Compra: producto.compra,
          "IVA compra": producto.iva_compra,
          Venta: producto.venta,
          "IVA venta": producto.iva_venta,
        };
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    utility() {
      return `${(((parseFloat(this.producto.venta) -
        parseFloat(this.producto.compra)) /
        parseFloat(this.producto.venta)) *
        100).toFixed(2)}%`;
    },
  },
  created() {
    this.loadProductos();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["caja"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      const productos = this.productos.map((producto) => {
        producto.compra = parseFloat(producto.compra);
        producto.venta = parseFloat(producto.venta);
        producto.iva_compra = parseFloat(producto.iva_compra);
        producto.iva_venta = parseFloat(producto.iva_venta);
        producto.inventario = parseInt(producto.inventario);
        let stock = producto.inventario == 1 ? "Venta" : "Consumo";
        let data = Object.assign({ stock: stock }, producto);

        return data;
      });

      const venta = productos.filter((producto) => {
        return producto.inventario == 1;
      });

      const consumo = productos.filter((producto) => {
        return producto.inventario == 2;
      });

      if (!this.consumo && this.venta) return venta;
      else if (!this.venta && this.consumo) return consumo;
      else if (this.venta && this.consumo) return productos;
      else return [];
    },
  },
};
</script>
