var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productos mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Productos ")]),_c('v-card-subtitle',[_vm._v(" Desde este apartado podrás modificar el valor de tus productos. "),_c('br'),_c('strong',[_vm._v(" El IVA solo afecta bajo un regimen diferente al simplificado ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.lista,"footer-props":{
                  itemsPerPageText: 'Productos',
                  itemsPerPageOptions: [10, 25, 50, 100],
                },"search":_vm.search,"options":{
                  itemsPerPage: 50,
                }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-btn',{attrs:{"dark":"","color":"black"},on:{"click":function($event){return _vm.download()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Descargar precios ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Productos de venta"},model:{value:(_vm.venta),callback:function ($$v) {_vm.venta=$$v},expression:"venta"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Productos de consumo"},model:{value:(_vm.consumo),callback:function ($$v) {_vm.consumo=$$v},expression:"consumo"}})],1)],1)],1)],1)]},proxy:true},{key:"item.compra",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.compra))+" ")]}},{key:"item.iva_compra",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.iva_compra)+"% ")]}},{key:"item.venta",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.venta))+" ")]}},{key:"item.iva_venta",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.iva_compra)+"% ")]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setEditValue(item)}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.producto)?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.producto.producto)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.producto.codigo)+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","type":"text","label":("Precio de compra " + (_vm.$options.filters.currency(
                              _vm.producto.compra
                            )))},model:{value:(_vm.producto.compra),callback:function ($$v) {_vm.$set(_vm.producto, "compra", _vm._n($$v))},expression:"producto.compra"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","type":"text","label":("IVA compra: " + (_vm.producto.iva_compra) + "%")},model:{value:(_vm.producto.iva_compra),callback:function ($$v) {_vm.$set(_vm.producto, "iva_compra", _vm._n($$v))},expression:"producto.iva_compra"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","type":"text","label":("Precio de venta " + (_vm.$options.filters.currency(
                              _vm.producto.venta
                            )))},model:{value:(_vm.producto.venta),callback:function ($$v) {_vm.$set(_vm.producto, "venta", _vm._n($$v))},expression:"producto.venta"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","type":"text","label":("IVA venta: " + (_vm.producto.iva_venta) + "%")},model:{value:(_vm.producto.iva_venta),callback:function ($$v) {_vm.$set(_vm.producto, "iva_venta", _vm._n($$v))},expression:"producto.iva_venta"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","readonly":"","type":"text","label":"% Utilidad","value":_vm.utility()}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"dark":!_vm.loading,"color":!_vm.loading ? 'black' : 'gray',"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.updateItem()}}},[_vm._v(" Actualizar ")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }